import { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react';
import { useLocation, useNavigate, Outlet, Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { useUser } from '../pages/auth/UserContext';
import { supabase } from '../services/supabaseClient';

import {
    BanknotesIcon,
    Bars3Icon,
    BellIcon,
    BookmarkSquareIcon,
    CogIcon,
    FireIcon,
    HomeIcon,
    InboxIcon,
    KeyIcon,
    MagnifyingGlassCircleIcon,
    PhotoIcon,
    SquaresPlusIcon,
    UserIcon,
    XMarkIcon,
    RectangleStackIcon,
    UserGroupIcon,
    Cog6ToothIcon,
    ChevronDownIcon,
  } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
  { name: 'My Tasks', href: '/tasks', icon: RectangleStackIcon },
  { name: 'Workflows', href: '/workflows', icon: Bars3Icon },
  { name: 'Clients', href: '/clients', icon: UserGroupIcon },
];


const subNavigation = [
    // {
    //   name: 'Account',
    //   description: 'Update my account information',
    //   href: '#',
    //   icon: CogIcon,
    //   current: true,
    // },
    {
      name: 'Workflows',
      description: 'Edit business workflows',
      href: '/settings',
      icon: Bars3Icon,
      current: false,
    },
    {
      name: 'Integrations',
      description: 'Update Integrations',
      href: '/integrations',
      icon: SquaresPlusIcon,
      current: false,
    }
    // {
    //   name: 'Organization Management',
    //   description: 'Update users and roles within your organization',
    //   href: '#',
    //   icon: PhotoIcon,
    //   current: false,
    // }
  ]
  

const userNavigation = [
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const handleLogout = async (navigate) => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      navigate('/login');
    }
  };
  
  export default function Layout() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const location = useLocation(); // Get the current location
    const navigate = useNavigate(); // Use navigate for programmatic navigation
    const { user, loading: userLoading } = useUser();
  
    return (
      <>
        {/* Mobile Sidebar */}
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
          <DialogBackdrop className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0" />
          <div className="fixed inset-0 flex">
            <DialogPanel className={`relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full ${isCollapsed ? 'w-20' : 'w-60'}`}>
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <div className={`flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10 transition-all duration-300 ${isCollapsed ? 'w-20' : 'w-60'}`}>
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=500"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              onMouseEnter={() => setIsCollapsed(false)} // Expand on hover
                              onMouseLeave={() => setIsCollapsed(true)} // Collapse on leave
                              className={classNames(
                                location.pathname === item.href ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                              {!isCollapsed && item.name} {/* Show text only when expanded */}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
  
        {/* Static sidebar for desktop */}
        <div className={`hidden lg:flex lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:flex lg:flex-col transition-all duration-300 ${isCollapsed ? 'w-20' : 'lg:w-60'}`} onMouseEnter={() => setIsCollapsed(false)} onMouseLeave={() => setIsCollapsed(true)}>
          <div className={`flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 transition-width duration-300 ${isCollapsed ? 'w-20' : 'w-60'}`}>
            <div className="flex h-16 shrink-0 items-center">
              <img
                alt="Your Company"
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=500"
                className="h-8 w-auto"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            location.pathname === item.href ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                          {!isCollapsed && item.name} {/* Show text only when expanded */}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <Link
                    to="/settings"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
                    {!isCollapsed && 'Settings'} {/* Show text only when expanded */}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
  
        <div className={`flex-1 transition-all duration-300 ${isCollapsed ? 'pl-20' : 'lg:pl-60'}`}> {/* Adjust main content padding based on sidebar state */}
          <div className="sticky top-0 z-40 flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm">
            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
  
            <div aria-hidden="true" className="h-6 w-px bg-gray-900/10 lg:hidden" />
            <div className="flex flex-1 gap-x-4 self-stretch">
              <form action="#" method="GET" className="relative flex flex-1">
                <label htmlFor="search-field" className="sr-only">Search</label>
                {/* <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                /> */}
                {/* <input
                  id="search-field"
                  name="search"
                  type="search"
                  placeholder="Search..."
                  placeholder="Search..."
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                /> */}
              </form>
  
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button> */}
  
                <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" />
  
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                      <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                    <span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true" className="ml-4 text-sm font-semibold leading-6 text-gray-900">
                        {user.name}
                      </span>
                      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
                    </span>
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none"
                  >
                    <MenuItem>
                      <button
                        onClick={() => handleLogout(navigate)}
                        className="block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900"
                      >
                        Sign Out
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>
          <div className="flex flex-1 xl:overflow-hidden">
            <nav
              aria-label="Sections"
              className="hidden w-96 flex-shrink-0 border-r border-slate-200 bg-white xl:flex xl:flex-col h-screen"
            >
              <div className="flex h-16 flex-shrink-0 items-center border-b border-slate-200 px-6">
                <p className="text-lg font-medium text-slate-900">Settings</p>
              </div>
              <div className="min-h-0 flex-1 overflow-y-auto">
                {subNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={classNames(
                      item.current ? 'bg-blue-50 bg-opacity-50' : 'hover:bg-blue-50 hover:bg-opacity-50',
                      'flex border-b border-slate-200 p-6'
                    )}
                  >
                    <item.icon aria-hidden="true" className="-mt-0.5 h-6 w-6 flex-shrink-0 text-slate-400" />
                    <div className="ml-3 text-sm">
                      <p className="font-medium text-slate-900">{item.name}</p>
                      <p className="mt-1 text-slate-500">{item.description}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </nav>

        {/* Main Content Area */}
        <main className="py-10 w-full">
          <div className="px-4 sm:px-6 lg:px-8">
            {/* This is where the routed content will be rendered */}
            <Outlet /> 
          </div>
        </main>
      </div>
      </div>
    </>
  );
}


