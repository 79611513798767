import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import TaskEditDrawer from './taskdrawer'; // Import the new task edit drawer
import { supabase } from '../../services/supabaseClient';
import { useUser } from '../auth/UserContext';
import { useParams } from 'react-router-dom';

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function TasksList() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, loading: userLoading } = useUser();
    const [selectedTask, setSelectedTask] = useState(null);
    const { workflow_id } = useParams();
    const [workflows, setWorkflows] = useState([]);
    const [workflowName, setWorkflowName] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [triggerOptions, setTriggerOptions] = useState([]);
    const [selectedTrigger, setSelectedTrigger] = useState(""); 

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                setLoading(true);
                const { data, error } = await supabase
                    .from('workflow_definitions')
                    .select('*')
                    .eq('id', workflow_id)
                    .single();

                if (error) throw error;
                setWorkflows(data);
                setWorkflowName(data.name); 
                setSelectedTrigger(data.trigger || ""); 
            } catch (err) {
                setError(err);
                console.error('Error fetching workflows:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, [workflow_id]);

    useEffect(() => {
        const fetchTasks = async () => {
            if (!user) return;

            try {
                console.log("Fetching tasks for Workflow ID:", workflow_id);
                setLoading(true);
                const { data, error } = await supabase
                    .from('task-definitions')
                    .select('*')
                    .eq('workflow_definition', workflow_id)
                    .order('created_at', { ascending: true });

                if (error) throw error;
                setTasks(data);
            } catch (err) {
                setError(err);
                console.error('Error fetching tasks:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTasks();
    }, [user]);

    useEffect(() => {
        const fetchTriggerOptions = async () => {
            try {
                const { data: triggersData, error: triggersError } = await supabase
                    .from('trigger-mapping')
                    .select('trigger, name');

                if (triggersError) throw triggersError;

                setTriggerOptions(triggersData);
            } catch (error) {
                console.error('Error fetching trigger options:', error);
            }
        };

        fetchTriggerOptions();
    }, []);

    const handleUpdateWorkflow = async () => {
        if (!workflow_id || !workflowName) return;

        const { error } = await supabase
            .from('workflow_definitions')
            .update({ name: workflowName, trigger: selectedTrigger })
            .eq('id', workflow_id);

        if (error) {
            console.error('Error updating workflow name:', error);
        } else {
            setIsEditing(false);
        }
    };

    if (userLoading || loading) {
        return <p>Loading tasks...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
            <div className="flex items-center space-x-4">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
                    {isEditing ? (
                        <input
                            type="text"
                            value={workflowName}
                            onChange={(e) => setWorkflowName(e.target.value)}
                            onBlur={handleUpdateWorkflow} // Update on losing focus
                            onKeyPress={(e) => { if (e.key === 'Enter') handleUpdateWorkflow(); }} // Update on Enter
                            className="border border-gray-300 rounded-md p-1"
                        />
                    ) : (
                        <span onDoubleClick={() => setIsEditing(true)} className="cursor-pointer">
                            {workflowName}
                        </span>
                    )}
                </h2>

                {/* Dropdown for Trigger */}
                <select
                    value={selectedTrigger}
                    onChange={(e) => setSelectedTrigger(e.target.value)}
                    className="border border-gray-300 rounded-md p-2"
                >
                    <option value="">Select Trigger</option>
                    {triggerOptions.map((trigger) => (
                        <option key={trigger.trigger} value={trigger.trigger}>
                            {trigger.name}
                        </option>
                    ))}
                </select>
            </div>

            {tasks.length === 0 ? (
                <p>No tasks in this workflow.</p>
            ) : (
                <ul role="list" className="divide-y divide-gray-100 mt-2">
                    {tasks.map((task) => {
                        const dueDate = new Date(task.due_date);
                        const formattedDueDate = dueDate.toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                        });
                        return (
                            <li key={task.id} className="flex items-center justify-between gap-x-6 py-5">
                                <div className="min-w-0">
                                    <div className="flex items-start gap-x-3">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">
                                            {task.name || 'N/A'}
                                        </p>
                                        <p
                                            className={classNames(
                                                statuses[task.type] || 'text-gray-500 bg-gray-50 ring-gray-500/10',
                                                'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                            )}
                                        >
                                            {task.type || 'N/A'}
                                        </p>
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        <p className="truncate">
                                            Assigned to {task.assignee ? task.assignee : 'Unknown'}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-none items-center gap-x-4">
                                    <button
                                        onClick={() => {
                                            setSelectedTask(task);
                                            setDrawerOpen(true); // Open the new TaskEditDrawer
                                        }}
                                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        View Task
                                        <span className="sr-only">, {task.name}</span>
                                    </button>

                                    <Menu as="div" className="relative flex-none">
                                        <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                                        </MenuButton>
                                        <MenuItems
                                            transition
                                            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none"
                                        >
                                            <MenuItem>
                                                <a
                                                    onClick={() => {
                                                        setSelectedTask(task);
                                                        setDrawerOpen(true); // Open the new TaskEditDrawer
                                                    }}
                                                    className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                                                >
                                                    Edit<span className="sr-only">, {task.name}</span>
                                                </a>
                                            </MenuItem>
                                        </MenuItems>
                                    </Menu>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            )}

            {/* Include the TaskEditDrawer for editing tasks */}
            <TaskEditDrawer 
                open={drawerOpen} 
                onClose={() => setDrawerOpen(false)} 
                task={selectedTask} 
                workflowId={workflow_id} 
                tenant={user?.tenant} // Assuming you have user object with tenant info
            />
        </>
    );
}


               
