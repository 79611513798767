export const abbreviateNumber = (num) => {
    if (num === null || num === undefined) return '0';

    const si = [
        { value: 1e9, symbol: 'B' }, // Billion
        { value: 1e6, symbol: 'M' }, // Million
        { value: 1e3, symbol: 'K' }   // Thousand
    ];

    const filter = si.filter(({ value }) => num >= value);
    if (filter.length > 0) {
        const { symbol, value } = filter[0];
        return (num / value).toFixed(0) + symbol; // Adjust decimal places as needed
    }

    return num.toString(); // Handle numbers less than 1000
};
