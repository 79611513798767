import React, { useState, useEffect } from 'react';
import { supabase } from '../../services/supabaseClient';
// import DynamicForm from '../../Forms/FormPreview';

const TaskEditDrawer = ({ open, onClose, task, workflowId, tenant }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [assignee, setAssignee] = useState('');
    const [type, setType] = useState('');
    const [priority, setPriority] = useState('');
    const [currentFormConfig, setCurrentFormConfig] = useState(null);
    const [users, setUsers] = useState([]);
    const [due, setDue] = useState('');
    const [crm, setCrm] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const { data, error } = await supabase
                    .from('users')
                    .select('name, id');
                if (error) throw error;
                setUsers(data);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();

        if (task) {
            setName(task.name || '');
            setDescription(task.description || '');
            setAssignee(task.assignee || '');
            setType(task.type || '');
            setPriority(task.priority || '');
            setCurrentFormConfig(task.form || '');
            setDue(task.due || ''); 
            setCrm(task.crm || '');
        } else {
            setName('');
            setDescription('');
            setAssignee('');
            setType('');
            setPriority('');
            setCurrentFormConfig('');
            setDue('');
            setCrm('');
        }
    }, [task]);

    const fetchTask = async (taskId) => {
        try {
            const { data, error } = await supabase
                .from('task-definitions')
                .select('*')
                .eq('id', taskId)
                .single(); // fetch a single task by id
            if (error) throw error;

            if (data) {
                // Update form fields with the fetched data
                setName(data.name || '');
                setDescription(data.description || '');
                setAssignee(data.assignee || '');
                setType(data.type || '');
                setPriority(data.priority || '');
                setCurrentFormConfig(data.form || '');
                setDue(data.due || '');
                setCrm(data.crm || '');
            }
        } catch (error) {
            console.error('Failed to fetch task:', error.message);
        }
    };

    const handleSave = async () => {
        try {
            if (task) {
                const { data, error } = await supabase
                    .from('task-definitions')
                    .update({
                        name,
                        description,
                        assignee,
                        type,
                        priority,
                        due,
                        crm
                    })
                    .eq('id', task.id);

                if (error) throw error;

                console.log("Successfully updated task:", task.id);
                // Refetch the updated task data
                await fetchTask(task.id);
            } else {
                const { data, error } = await supabase
                    .from('task-definitions')
                    .insert({
                        name,
                        description,
                        tenant,
                        assignee,
                        type,
                        priority,
                        crm,
                        workflow_definition: workflowId
                    });

                if (error) throw error;
            }

            onClose(); // Close the drawer after saving
        } catch (error) {
            console.error('Failed to update or create task:', error.message);
        }
    };

    return (
        <div className={`${open ? 'block' : 'hidden'} fixed top-0 right-0 z-50 w-full h-full bg-gray-800 bg-opacity-50`}>
            <div className={`relative bg-white w-96 h-full p-6 transition-transform duration-500 ${open ? 'translate-x-0' : '-translate-x-full'}`}>
                <div className="flex justify-between">
                    <h2 className="text-xl font-semibold">{task ? 'Edit Task' : 'New Task'}</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-900">
                        &times;
                    </button>
                </div>
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                            rows="4"
                        />
                    </div>
                    {/* Assignee Dropdown */}
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Assignee</label>
                        <select 
                            value={assignee} 
                            onChange={(e) => setAssignee(e.target.value)} 
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        >
                            <option value="">Select Assignee</option>
                            {users.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.name}
                                </option>
                            ))}
                            <option value="Assistant">Any Assistant</option>
                            <option value="Advisor">Any Advisor</option>
                        </select>
                    </div>
                    {/* Priority Dropdown */}
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Priority</label>
                        <select 
                            value={priority} 
                            onChange={(e) => setPriority(e.target.value)} 
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        >
                            <option value="false">Low</option>
                            <option value="true">High</option>
                        </select>
                    </div>

                    {/* CRM Dropdown */}
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Work in CRM</label>
                        <select 
                            value={crm} 
                            onChange={(e) => setCrm(e.target.value)} 
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    {/* Type Dropdown */}
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Type</label>
                        <select 
                            value={type} 
                            onChange={(e) => setType(e.target.value)} 
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        >
                            <option value="Automated">Automated</option>
                            <option value="Internal">Internal</option>
                        </select>
                    </div>
                    {/* Due Dropdown */}
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Due</label>
                        <select 
                            value={due}
                            onChange={(e) => setDue(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        >
                            <option value="0">Same Day</option>
                            <option value="1">1 Day</option>
                            <option value="3">3 Days</option>
                            <option value="7">7 Days</option>
                            <option value="30">30 Days</option>
                        </select>
                    </div>
    
                    {currentFormConfig ? (
                        <div className="mt-6 p-4 bg-gray-100 rounded-md">
                            <div className="text-lg font-semibold">Task Details</div>
                            {/* <DynamicForm config={currentFormConfig} /> */}
                        </div>
                    ) : (
                        <div className="mt-6 text-gray-500">No questions on this task.</div>
                    )}
    
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={handleSave}
                            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        );
    };
    
    export default TaskEditDrawer;
    
