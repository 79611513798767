import { supabase } from '../../../services/supabaseClient';
import { abbreviateNumber } from './abbreviateNumber';

export const fetchAUM = async () => {
    try {
        const { data, error } = await supabase
            .from('organizations')
            .select('aum'); // Fetch the AUM field from the organizations table

        if (error) {
            console.error('Error fetching AUM:', error);
            return null;
        }

        // Check if the data is available and contains AUM
        if (data && data.length > 0) {
            const aumValue = data[0].aum; // Get the AUM value
            return `$${abbreviateNumber(aumValue)}`; // Format the value
        } else {
            console.log("No AUM found");
            return "$0"; // No AUM found
        }
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
};
