import React from 'react';
import TasksTable from './TasksTable'; // Ensure correct path

export default function Tasks() {
  return (
    <div>
      <TasksTable /> 
    </div>
  );
}
