import React, { useState, useEffect } from 'react';
import { supabase } from '../../services/supabaseClient';
import { useAuth } from '../auth/AuthContext';

const RedtailIntegration = () => {
    const [redtailUsername, setRedtailUsername] = useState('');
    const [redtailPassword, setRedtailPassword] = useState('');
    const [isRedtailConfigured, setIsRedtailConfigured] = useState(false);
    const [tenant_id, setTenantId] = useState(false);

    useEffect(() => {
        const fetchRedtailConfig = async () => {
            try {
                const { data, error } = await supabase
                    .from('organizations')
                    .select('redtail, id')
                    .single();

                if (error) {
                    throw error;
                }
                setIsRedtailConfigured(!!data.redtail);
                setTenantId(data.id);
            } catch (error) {
                console.error('Error fetching Redtail configuration:', error);
            }
        };

        fetchRedtailConfig();
    }, [tenant_id]);

    const handleRedtailSubmit = async () => {
        console.log(tenant_id + redtailUsername + redtailPassword);
        try {
            const token = (await supabase.auth.getSession()).data.session?.access_token;

            const { response, error } = await supabase.functions.invoke('redtail-auth', {
                body: JSON.stringify({
                    tenant: tenant_id,
                    username: redtailUsername,
                    password: redtailPassword,
                }),
            });

            const data = response;
            setIsRedtailConfigured(true);
        } catch (error) {
            console.error('Error submitting Redtail credentials:', error);
        }
    };

    return (
        <div className="mt-3 p-5 border rounded-md shadow-md bg-white max-w-[300px] ml-5">
            <div className="flex justify-center mb-4">
                <img 
                    src={`${process.env.PUBLIC_URL}/redtail2.png`} 
                    alt="Redtail Integration" 
                    className="max-w-full h-auto" 
                />
            </div>
            <form className="flex flex-col space-y-4" noValidate autoComplete="off">
                <input
                    type="text"
                    placeholder="Username"
                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-200"
                    value={redtailUsername}
                    onChange={(e) => setRedtailUsername(e.target.value)}
                    disabled={isRedtailConfigured}
                />
                <input
                    type="password"
                    placeholder="Password"
                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-200"
                    value={redtailPassword}
                    onChange={(e) => setRedtailPassword(e.target.value)}
                    disabled={isRedtailConfigured}
                />
                <button
                    type="button"
                    onClick={handleRedtailSubmit}
                    className="bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
                    disabled={isRedtailConfigured}
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default RedtailIntegration;
