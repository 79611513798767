import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { supabase } from '../../services/supabaseClient'; // Adjust the import based on your project structure

// Register necessary components from Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch tasks
        const { data: tasks, error: tasksError } = await supabase
          .from('tasks')
          .select('Type, Assignee')
          .eq('status', 'Complete'); // Filter for completed tasks

        if (tasksError) {
          console.error('Error fetching tasks:', tasksError);
          return;
        }

        // Fetch users with roles
        const { data: users, error: usersError } = await supabase
          .from('users')
          .select('id, Role');

        if (usersError) {
          console.error('Error fetching users:', usersError);
          return;
        }

        // Filtering Users by Role
        const assistants = users.filter(user => user.Role === 'Assistant').map(user => user.id);
        const advisors = users.filter(user => user.Role === 'Advisor').map(user => user.id);

        // Counting tasks
        const taskCount = {
          Automated: tasks.filter(task => task.Type === 'Automated').length,
          Assistant: tasks.filter(task => task.Type === 'Internal' && assistants.includes(task.Assignee)).length,
          Advisor: tasks.filter(task => task.Type === 'Internal' && advisors.includes(task.Assignee)).length,
        };
        // Preparing data for the doughnut chart
        const dataForChart = [
          { label: 'Automated Tasks', value: taskCount.Automated },
          { label: 'Assistant Tasks', value: taskCount.Assistant },
          { label: 'Advisor Tasks', value: taskCount.Advisor },
        ];

        setChartData(dataForChart);

      } catch (err) {
        console.error('Error:', err);
      }
    };

    fetchData();
  }, []);

  // Create chart data structure for Chart.js
  const chartConfig = {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        label: 'Task Distribution',
        data: chartData.map(item => item.value),
        backgroundColor: [
          '#5B93FF',
          '#FFD66B',
          '#FF8F6B',
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true, // Display tooltips on hover
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start'
      },
    },
  };

  return (
    <div className="flex flex-col items-center">
      <div style={{ width: '100%', height: '300px' }}> {/* Responsive size */}
        <Doughnut data={chartConfig} options={options} />
      </div>
    </div>
  );
};

export default DoughnutChart;
