import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { supabase } from '../../services/supabaseClient'; // Import Supabase client

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function WorkflowList() {
    const [workflows, setWorkflows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                setLoading(true);
                // Fetch workflows and join with customers table
                const { data, error } = await supabase
                    .from('workflow_definitions')
                    .select('*') // Select workflows and customer names
                    .order('created_at', { ascending: true }); // Order by creation date

                if (error) throw error;
                setWorkflows(data);
            } catch (err) {
                setError(err);
                console.error('Error fetching workflows:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, []);

    if (loading) {
        return <p>Loading workflows...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
      <>
      
      <h2 className="text-lg font-medium leading-6 text-gray-900">Workflows</h2>
        <ul role="list" className="divide-y divide-gray-100">
            {workflows.map((workflow) => {
                // Format created_at date
                const createdAt = new Date(workflow.created_at);
                const formattedCreatedAt = createdAt.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                });

                return (
            
                    <li key={workflow.id} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                    {workflow.name}
                                </p>
                                {/* <p
                                    className={classNames(
                                        statuses[workflow.status] ||
                                            'text-gray-500 bg-gray-50 ring-gray-500/10',
                                        'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                    )}
                                >
                                    {workflow.status}
                                </p> */}
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="whitespace-nowrap">
                                    Created on {' '}
                                    <time dateTime={workflow.created_at}>
                                        {formattedCreatedAt}
                                    </time>
                                </p>
                                {/* <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                    <circle r={1} cx={1} cy={1} />
                                </svg> */}
                                {/* <p className="truncate">
                                    Customer Name - {workflow.customers.name || 'Unknown'}
                                </p> */}
                            </div>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                            <a
                                href={`/workflowtasks/${workflow.id}`}
                                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                View Workflow
                                <span className="sr-only">, {workflow.workflow_name}</span>
                            </a>
                            {/* ... your menu remains the same ... */}
                        </div>
                    </li>
                );
            })}
        </ul>
        </>
    );
}
