import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import RightDrawer from '../tasks/taskdrawer';
import { supabase } from '../../services/supabaseClient';
import { useUser } from '../auth/UserContext';
import { useParams } from 'react-router-dom';

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function TasksList() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user, loading: userLoading } = useUser();
    const [selectedTask, setSelectedTask] = useState(null);
    const { workflow_id } = useParams();
    const [workflows, setWorkflows] = useState([]);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                setLoading(true);
                const { data, error } = await supabase
                    .from('workflows')
                    .select('*') 
                    .eq('workflow_id', workflow_id)
                    .single();

                if (error) throw error;
                setWorkflows(data);
            } catch (err) {
                setError(err);
                console.error('Error fetching workflows:', err);
            } finally {
                setLoading(false);
            }
        };
        
        fetchWorkflows();
    }, [workflow_id]);

    const fetchTasks = async () => {
        if (!user) return;

        try {
            console.log("Fetching tasks for Workflow ID:", workflow_id);
            setLoading(true);
            const { data, error } = await supabase
                .from('tasks')
                .select('*, users(name)') 
                .eq('workflow_id', workflow_id)
                .order('created_at', { ascending: true });

            if (error) throw error;
            setTasks(data);
        } catch (err) {
            setError(err);
            console.error('Error fetching tasks:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTasks();
    }, [user, workflow_id]);

    // Set up real-time subscriptions
    useEffect(() => {
        const taskChannel = supabase
            .channel('schema-db-changes')
            .on('postgres_changes', {
                event: 'UPDATE',
                schema: 'public',
                table: 'tasks',
                filter: `workflow_id=eq.${workflow_id}`,
            }, (payload) => {
                console.log('Task updated:', payload);
                setTasks(prevTasks => 
                    prevTasks.map(task =>
                        task.id === payload.new.id ? { ...task, ...payload.new } : task
                    )
                ); // Update local state with new task data
            })
            .on('postgres_changes', {
                event: 'INSERT',
                schema: 'public',
                table: 'tasks',
                filter: `workflow_id=eq.${workflow_id}`,
            }, (payload) => {
                console.log('New task added:', payload);
                setTasks(prevTasks => [...prevTasks, payload.new]); // Add new task to local state
            })
            .subscribe();

        return () => {
            taskChannel.unsubscribe(); // Cleanup subscription on unmount
        };
    }, [workflow_id]);

    const handleTaskCompletion = async (taskId) => {
        await supabase
            .from('tasks')
            .update({ status: 'Complete' })
            .eq('id', taskId);
        // The subscription will handle the task update
    };
    if (userLoading || loading) {
        return <p>Loading tasks...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
            <h2 className="text-lg font-medium leading-6 text-gray-900">Workflow Details - {workflows.workflow_name}</h2>
            {tasks.length === 0 ? (
                <p>You have no assigned tasks.</p>
            ) : (
                <ul role="list" className="divide-y divide-gray-100 mt-2">
                    {tasks.map((task) => {
                        const dueDate = new Date(task.due_date);
                        const formattedDueDate = dueDate.toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                        });
                        return (
                            <li key={task.id} className="flex items-center justify-between gap-x-6 py-5">
                                <div className="min-w-0">
                                    <div className="flex items-start gap-x-3">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">
                                            {task.Name || 'N/A'}
                                        </p>
                                        <p
                                            className={classNames(
                                                statuses[task.status] || 'text-gray-500 bg-gray-50 ring-gray-500/10',
                                                'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                            )}
                                        >
                                            {task.status || 'N/A'}
                                        </p>
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        <p className="whitespace-nowrap">
                                            Due on{' '}
                                            <time dateTime={task.due_date}>{formattedDueDate}</time>
                                        </p>
                                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                            <circle r={1} cx={1} cy={1} />
                                        </svg>
                                        <p className="truncate">
                                            Assigned to {task.users ? task.users.name : 'Unknown'}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-none items-center gap-x-4">
                                    <button
                                        onClick={() => {
                                            setDrawerOpen(true);
                                            setSelectedTask(task); // Set the task to be edited
                                        }}
                                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        View Task
                                        <span className="sr-only">, {task.Name}</span>
                                    </button>
                                    <Menu as="div" className="relative flex-none">
                                        <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                                        </MenuButton>
                                        <MenuItems
                                            transition
                                            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                            <MenuItem>
                                                <a
                                                    onClick={() => {
                                                        setDrawerOpen(true);
                                                        setSelectedTask(task); // Set the task to be edited
                                                    }}
                                                    className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                                                >
                                                    Edit<span className="sr-only">, {task.Name}</span>
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <button
                                                    onClick={() => handleTaskCompletion(task.id)} // Handle task completion
                                                    className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                                                >
                                                    Complete<span className="sr-only">, {task.Name}</span>
                                                </button>
                                            </MenuItem>
                                        </MenuItems>
                                    </Menu>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            )}
                        <RightDrawer open={drawerOpen} setOpen={setDrawerOpen} task={selectedTask} />
        </>
    );
}



   
