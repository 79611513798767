import React from 'react';
import WorkflowsTable from './workflowstable'; // Ensure correct path

export default function Workflows() {
  return (
    <div>
      <WorkflowsTable /> 
    </div>
  );
}
