import { PaperClipIcon } from '@heroicons/react/20/solid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState, useEffect } from 'react';
import DynamicForm from './dynamicform';
import Document from './document.js';

export default function DrawerDetails({ task, customer, setFormData }) {
  const [selectedDate, setSelectedDate] = useState(task?.due_date);
  const [currentFormConfig, setCurrentFormConfig] = useState(null);
  const [formAnswers, setFormAnswers] = useState(task?.status === 'Complete' ? task?.answer : {});

  useEffect(() => {
    if (task?.form) {
      setCurrentFormConfig(task.form);

      // Load answers only if task is complete
      if (task.status === 'Complete' && task.answer) {
        setFormAnswers(task.answer);
      }
    }
  }, [task]);

  const handleFormSubmit = (responses) => {
    setFormData(responses);  // Call parent to update form data
  };

  return (
    <div>
      <div>
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Customer Name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customer?.name || 'Unknown'}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Assignee</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{task?.users?.name || 'Unknown'}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Due Date</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="MM/dd/yyyy"
                className="border rounded-md"
              />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Details</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {task?.description || 'No description available'}
            </dd>
          </div>
        </dl>
      </div>
      <div>
      {task?.document_id && <Document documentId={task.document_id} />} 
        {currentFormConfig && (
          <>
            {task.status === 'Complete' ? (
              <div>
                <h3 className="text-base font-semibold leading-6 text-gray-900">Form Answers</h3>
                <ul>
                  {Object.entries(formAnswers).map(([key, value]) => (
                    <li key={key}>
                      <strong>{key}:</strong> {value !== null ? String(value) : 'Not Provided'}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div>
                <h1 id="message-heading" className="text-base font-semibold leading-6 text-gray-900">
                  Task Questions
                </h1>
                <DynamicForm config={currentFormConfig} onFormChange={setFormData} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
