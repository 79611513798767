import { supabase } from '../../../services/supabaseClient';

export const fetchNewCustomers = async () => {
    try {
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const startOfMonthUTC = startOfMonth.toISOString(); // Full date and time

        // Log the date you are querying against
        console.log('Fetching customers starting from:', startOfMonthUTC);

        // Fetch all customers created in the month
        const { data, error: fetchError } = await supabase
            .from('customers')
            .select('*')
            .gte('created_at', startOfMonthUTC);

        if (fetchError) {
            console.error('Error fetching customers:', fetchError);
            return null;
        }

        // Log the fetched data
        
        const customerCount = data.length; // Calculate count based on retrieved data
        return customerCount;
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
};
