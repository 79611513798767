import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useEffect } from 'react';

export default function DrawerHeader({ task, workflow }) {
  useEffect(() => {
    console.log('Task:', task);
  }, [task, workflow]);

  return (
    <div className="border-b border-gray-200 pb-5">
      <div className="sm:flex sm:items-baseline sm:justify-between">
        <div className="sm:w-0 sm:flex-1">
          <h1 id="message-heading" className="text-base font-semibold leading-6 text-gray-900">
            {task?.Name || 'No Task Name'}
          </h1>
          <p className="mt-1 truncate text-sm text-gray-500">{workflow?.workflow_name || 'Unknown'}</p>
        </div>

        <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
          <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {task?.status || 'Unknown'}
          </span>
        </div>
      </div>
    </div>
  )
}
