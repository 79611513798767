import React, { useState, useEffect } from 'react';
import { supabase } from '../../../services/supabaseClient';

const taskStatuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Pending: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    // Add other statuses and styles as needed
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const TaskTable = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const { data: tasksData, error: tasksError } = await supabase
                    .from('tasks')
                    .select('*')
                    .eq('status', 'Incomplete')
                    .eq('priority', 'True');

                if (tasksError) throw tasksError;
                const { data: usersData, error: usersError } = await supabase
                    .from('users')
                    .select('*');

                if (usersError) throw usersError;
                const usersMap = usersData.reduce((map, user) => {
                    // More robust key access, handling potential missing fields:
                    const userId = user.id !== undefined ? user.id : user.user_id; // Check for 'id' or 'user_id'
                    const userName = user.name !== undefined ? user.name : user.user_name; //Check for 'name' or 'user_name'
                
                    map[userId] = userName;
                    return map;
                }, {});

                const tasksWithAssigneeNames = tasksData.map((task) => {
                    const assigneeId = task.Assignee !== undefined ? task.Assignee : task.Assignee; //Handle different casing
                    return {
                        ...task,
                        assigneeName: usersMap[assigneeId] || 'Unknown',
                    };
                });
                setTasks(tasksWithAssigneeNames);

            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, []);

    if (loading) return <p>Loading tasks...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="flex flex-col p-6 bg-white rounded-lg border border-gray-200 w-full h-[450px] overflow-y-auto"> {/*Tailwind CSS Styling */}
            <h3 className="text-lg font-medium text-gray-900">High Priority Tasks</h3> {/*Title*/}
            {tasks.length === 0 ? (
                <p>No outstanding high priority tasks.</p>
            ) : (
                <ul role="list" className="divide-y divide-gray-100 mt-2">
                {tasks.map((task) => (
                    <li key={task.id} className="flex items-center justify-between py-2">
                    <div className="flex flex-col">
                        <span className="text-sm font-semibold text-gray-900">
                        {task.Name}
                        </span>
                        <span className="mt-1 text-xs text-gray-500">
                        {task.assigneeName}
                        </span>
                    </div>
                    <div className="flex-none"> {/*Added this div to hold the status badge*/}
                                <span
                                    className={classNames(
                                        taskStatuses[task.status] || 'text-gray-500 bg-gray-50 ring-gray-500/10',
                                        'mt-0.5 inline-flex rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                    )}
                                >
                                    {task.status || 'N/A'}
                                </span>
                            </div>
                    </li>
                ))}
                </ul>
            )}
            </div>
       
            // <ul role="list" className="divide-y divide-gray-100 mt-2">
            //     {tasks.map((task) => (
            //         <li key={task.id} className="flex items-center justify-between py-2">
            //             <div className="flex flex-col">
            //                 <span className="text-sm font-semibold text-gray-900">{task.Name || 'N/A'}</span>
            //                 <span className="mt-1 text-xs text-gray-500">
            //                     Assigned to: {task.assigneeName || 'N/A'}
            //                 </span>
            //             </div>
                      
            //         </li>
            //     ))}
            // </ul>
    );
};

export default TaskTable;
