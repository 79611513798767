// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../../services/supabaseClient';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to check session and retrieve user details
    const fetchUserData = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error fetching session:', error.message);
        setUser(null);
        setLoading(false);
        return;
      }

      if (!session) {
        // If no session, no user is authenticated
        setUser(null);
        setTenantId(null);
        setLoading(false);
        return;
      }

      const userEmail = session.user.email;

      // Fetch user record from the users table
      const { data: userRecord, error: fetchError } = await supabase
        .from('users')
        .select('name, email, phone, company, tenant_id, id')
        .eq('email', userEmail)
        .single();

      if (fetchError) {
        console.error('Error fetching user record:', fetchError.message);
        setUser(null);
        setTenantId(null);
      } else if (userRecord) {
        // Update context with user record if found
        setUser(userRecord);
        setTenantId(userRecord.tenant_id);
      } else {
        setUser(null);
        setTenantId(null);
      }

      setLoading(false);
    };

    fetchUserData();
  }, []);

  const value = {
    user,
    setUser, // Allow updating the user state
    tenantId,
    loading,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
