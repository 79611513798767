import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DynamicForm = ({ config, onFormChange }) => {
  const initialValues = {};
  config?.forEach((field) => {
    initialValues[field.name] = field.type === 'checkbox' ? false : '';
  });

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    onFormChange(formValues);
  }, [formValues, onFormChange]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleDateChange = (name) => (newValue) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: newValue ? newValue.toISOString().split('T')[0] : '',
    }));
  };

  return (
    <div className="w-full mt-5">
      {config.map((field, index) => (
        <div key={index} className="w-full max-w-2xl mx-auto mb-4">
          {['text', 'email', 'password'].includes(field.type) && (
            <input
              type={field.type}
              id={field.name}
              name={field.name}
              value={formValues[field.name] || ''}
              onChange={handleChange}
              placeholder={field.placeholder || "Your Placeholder"}
              className="w-full border rounded px-3 py-2"
            />
          )}

          {field.type === 'select' && (
            <div>
              <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                {field.label}
              </label>
              <Menu as="div" className="relative inline-block text-left w-full">
                <MenuButton className="w-full rounded bg-white px-3 py-2 mt-1 text-gray-900 shadow-sm focus:outline-none">
                  {formValues[field.name] || ""}
                  <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" />
                </MenuButton>
                <MenuItems className="absolute left-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg">
                  {field.options.map((option) => (
                    <MenuItem key={option}>
                      {({ active }) => (
                        <button
                          onClick={() =>
                            handleChange({ target: { name: field.name, value: option } })
                          }
                          className={`${active ? 'bg-gray-100' : ''} group flex items-center w-full px-2 py-2 text-sm text-gray-900`}
                        >
                          {option}
                        </button>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
            </div>
          )}

          {field.type === 'textarea' && (
            <textarea
              id={field.name}
              name={field.name}
              value={formValues[field.name] || ''}
              onChange={handleChange}
              placeholder={field.placeholder || "Your Placeholder"}
              className="w-full border rounded px-3 py-2"
            />
          )}

          {field.type === 'checkbox' && (
            <div className="flex items-center">
              <input
                type="checkbox"
                id={field.name}
                name={field.name}
                checked={formValues[field.name]}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor={field.name} className="ml-2 text-gray-700">
                {field.label}
              </label>
            </div>
          )}

          {field.type === 'date' && (
            <div>
              <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                {field.label}
              </label>
              <DatePicker
                id={field.name}
                name={field.name}
                selected={formValues[field.name] ? new Date(formValues[field.name]) : null}
                onChange={handleDateChange(field.name)}
                dateFormat="yyyy-MM-dd"
                className="w-full border rounded px-3 py-2 mt-1"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DynamicForm;
