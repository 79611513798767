import React, { useState, useEffect } from 'react';
import { HomeIcon, RectangleStackIcon, UsersIcon, ChartPieIcon } from '@heroicons/react/24/outline';
import PieChart from './piechart';
import TaskTable from './functions/hightasks'; // Adjust path if needed
import { fetchCustomerCount } from './functions/fetchCustomerCount';
import { fetchAUM } from './functions/fetchAUM';
import { fetchOnboardingCustomers } from './functions/getOnboardingCustomers';
import { fetchNewCustomers } from './functions/fetchNewCustomers';
import CustomerOnboarding from './functions/customersonboarding';

const projectStatuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function CardGrid() {
    const [customerCount, setCustomerCount] = useState(0);
    const [onboardingCount, setOnboardingCount] = useState(0);
    const [newCount, setNewCount] = useState(0);
    const [AUM, setAUM] = useState('$0');
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [customerCountRes, onboardingCountRes, newCountRes, aumRes] = await Promise.all([
                    fetchCustomerCount(),
                    fetchOnboardingCustomers(),
                    fetchNewCustomers(),
                    fetchAUM(),
                ]);
                setCustomerCount(customerCountRes);
                setOnboardingCount(onboardingCountRes);
                setNewCount(newCountRes);
                setAUM(aumRes);
                setDataLoaded(true);
            } catch (error) {
                console.error('Error fetching data:', error);
                setDataLoaded(true); // Set to true even on error
            }
        };

        fetchData();
    }, []);

    const cardData = [
        {
            title: customerCount,
            description: 'New Clients',
            icon: <HomeIcon className="h-6 w-6 text-gray-600" />,
        },
        {
            title: newCount,
            description: 'New Accounts MTD',
            icon: <RectangleStackIcon className="h-6 w-6 text-gray-600" />,
        },
        {
            title: onboardingCount,
            description: 'Customers onboarding',
            icon: <UsersIcon className="h-6 w-6 text-gray-600" />,
        },
        {
            title: AUM,
            description: 'AUM',
            icon: <ChartPieIcon className="h-6 w-6 text-gray-600" />,
        },
    ];

    const projects = [
        // Sample project data; replace with your actual project data
        {
            id: 1,
            name: 'Project A',
            href: '#',
            status: 'In progress',
            createdBy: 'User X',
            dueDate: '2024-11-15',
            dueDateTime: '2024-11-15T00:00Z',
        },
        {
            id: 2,
            name: 'Project B',
            href: '#',
            status: 'Complete',
            createdBy: 'User Y',
            dueDate: '2024-10-20',
            dueDateTime: '2024-10-20T00:00Z',
        },
    ];

    return (
        <div className="flex flex-col h-screen px-4 sm:px-6 lg:px-8">
            <h2 className="text-lg font-medium leading-6 text-gray-900">Dashboard Overview</h2>

            {dataLoaded && (
                <div className="mt-6 grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
                    {cardData.map((card, index) => (
                        <div key={index} className="flex justify-center">
                            <div className="flex items-center p-6 bg-white rounded-lg border border-gray-200 w-80 h-32">
                                <div className="mr-4">{card.icon}</div>
                                <div className="flex flex-col">
                                    <h3 className="text-lg font-medium text-gray-900">{card.title}</h3>
                                    <p className="mt-1 text-xs text-gray-500">{card.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {dataLoaded && (
                <div className="flex-grow mt-6 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="col-span-1 flex justify-center">
                        <TaskTable /> {/*Render the CustomerOnboarding component here*/}
                    </div>

                    {/* Replace this section with CustomerOnboarding component */}
                    <div className="col-span-1 flex justify-center">
                        <CustomerOnboarding /> {/*Render the CustomerOnboarding component here*/}
                    </div>

                    <div className="col-span-1 flex justify-center h-[450px]">
                        <div className="flex flex-col p-6 bg-white rounded-lg border border-gray-200 w-full h-full">
                            <h3 className="text-lg font-medium text-gray-900">Task Analytics</h3>
                            <PieChart />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
