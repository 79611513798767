'use client'

import React, { useState, useEffect } from 'react';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import DrawerHeader from './components/drawerheader';
import DrawerDetails from './components/drawerdetails';
import { supabase } from '../../services/supabaseClient';

export default function RightDrawer({ open, setOpen, task }) {
  const [customer, setCustomer] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (task) {
      fetchCustomerAndWorkflow();
    }
  }, [task]);

  const fetchCustomerAndWorkflow = async () => {
    if (!task) return;

    // Fetch customer
    const { data: customerData, error: customerError } = await supabase
      .from('customers')
      .select('*')
      .eq('id', task.customer_id)
      .single();

    if (customerError) {
      console.error('Error fetching customer:', customerError);
    } else if (customerData) {
      setCustomer(customerData);
    }

    // Fetch workflow
    const { data: workflowData, error: workflowError } = await supabase
      .from('workflows')
      .select('*')
      .eq('workflow_id', task.workflow_id)
      .single();

    if (workflowError) {
      console.error('Error fetching workflow:', workflowError);
    } else if (workflowData) {
      setWorkflow(workflowData);
    }
  };

  const handleCompleteTask = async () => {
    try {
      // Update the task, including form data in the answer field
      const { error: updateError } = await supabase
        .from('tasks')
        .update({
          status: 'Complete',
          answer: formData, // Pass the form data here
        })
        .eq('id', task.id);

      if (updateError) throw updateError;

      setOpen(false); // Close the drawer on success
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  if (!task) {
    return null; // Render nothing if task is not available
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out">
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">Task Details</DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <DrawerHeader task={task} workflow={workflow} />
                  <DrawerDetails task={task} customer={customer} setFormData={setFormData} />
                  
                  <div className="flex justify-end mt-4">
                    {task.status !== 'Complete' && (
                      <button
                        type="button"
                        onClick={handleCompleteTask}
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Complete Task
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
