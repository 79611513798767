import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient'; // Adjust the import path if necessary

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

export default function ClientDetails() {
    const { id } = useParams(); // Get customer ID from URL parameters
    const [workflows, setWorkflows] = useState([]);
    const [clientName, setClientName] = useState([]); // State to hold client name
    const [documents, setDocuments] = useState([]); // State to hold documents
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchDocuments = async () => {
        try {
            const { data: documentsData, error: documentsError } = await supabase
                .from('documents')
                .select('*')
                .eq('customer_id', id);

            if (documentsError) throw documentsError;
            setDocuments(documentsData);
        } catch (err) {
            console.error('Error fetching documents:', err);
        }
    };
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch workflows for the client
                const { data: workflowsData, error: workflowsError } = await supabase
                    .from('workflows')
                    .select('*')
                    .eq('customer', id); // Ensure 'customer' field matches with the customer's ID

                if (workflowsError) throw workflowsError;
                setWorkflows(workflowsData);

                // Fetch client name
                const { data: clientData, error: clientError } = await supabase
                    .from('customers')
                    .select('*')
                    .eq('id', id)
                    .single(); // Fetch single customer by id

                if (clientError) throw clientError;
                setClientName(clientData);

                // Fetch documents     
                fetchDocuments();           
            } catch (err) {
                setError(err);
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const getSignedUrl = async (path) => {
        try {
            const { data, error } = await supabase
                .storage
                .from('documents') // Replace with your actual bucket name
                .createSignedUrl(path, 60); // Replace 60 with desired expiration time in seconds

            if (error) throw error;
            return data?.signedUrl;
        } catch (err) {
            console.error('Error getting signed URL:', err);
            return null;
        }
    };

    if (loading) {
        return <p>Loading data...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Client Profile for {clientName.name}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                    Below is client information and workflows that are running and have run for this client.
                </p>

                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">{clientName.name}</div>
                        </dd>
                    </div>
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">{clientName.email}</div>
                        </dd>
                    </div>
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Phone</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">{clientName.phone_number}</div>
                        </dd>
                    </div>
                </dl>
            </div>

            <div className="flex mt-8 space-x-8">
                <div className="w-2/3">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">Workflows</h2>
                    <ul role="list" className="divide-y divide-gray-100">
                        {workflows.map((workflow) => {
                            const createdAt = new Date(workflow.created_at);
                            const formattedCreatedAt = createdAt.toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                            });

                            return (
                                <li key={workflow.id} className="flex items-center justify-between gap-x-6 py-5">
                                    <div className="min-w-0">
                                        <div className="flex items-start gap-x-3">
                                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                                {workflow.workflow_name}
                                            </p>
                                            <p
                                                className={classNames(
                                                    statuses[workflow.status] ||
                                                    'text-gray-500 bg-gray-50 ring-gray-500/10',
                                                    'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                                )}
                                            >
                                                {workflow.status}
                                            </p>
                                        </div>
                                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                            <p className="whitespace-nowrap">
                                                Started on{' '}
                                                <time dateTime={workflow.created_at}>
                                                    {formattedCreatedAt}
                                                </time>
                                            </p>
                                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                                <circle r={1} cx={1} cy={1} />
                                            </svg>
                                            <p className="truncate">
                                                Customer Name - {clientName.name ? clientName.name : 'Unknown'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">
                                        <a
                                            href={`/workflows/${workflow.workflow_id}`}
                                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            View Workflow
                                            <span className="sr-only">, {workflow.workflow_name}</span>
                                        </a>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="w-1/3">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">Documents</h2>
                    <ul role="list" className="divide-y divide-gray-100">
                        {documents.map((doc) => (
                            <li key={doc.id} className="flex items-center justify-between gap-x-6 py-5">
                                <div className="flex min-w-0 gap-x-4">
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-sm font-semibold text-gray-900">{doc.document_name}</p>
                                    </div>
                                </div>
                                <button
                                    className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    onClick={async () => {
                                        const signedUrl = await getSignedUrl(doc.document_path); // Ensure 'path' is the correct field
                                        if (signedUrl) {
                                            window.open(signedUrl, '_blank');
                                        }
                                    }}
                                >
                                    Download
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}
