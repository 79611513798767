import React, { useState, useEffect } from 'react';
import { useUser } from '../../auth/UserContext'; // Adjust import path if needed
import { supabase } from '../../../services/supabaseClient'; // Adjust import path if needed

const CustomerOnboarding = () => {
  const { tenantId, loading: loadingUser } = useUser();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!tenantId) {
        console.error('No tenant ID available.');
        return;
      }
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('customers')
          .select('*')
          .eq('onboarded', 'FALSE'); // Filter for non-onboarded customers
        if (error) throw error;

        
        setCustomers(data);
        
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, [tenantId]);


  if (loading || loadingUser) {
    return <div>Loading...</div>; // Simple loading indicator
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Simple error display
  }

  return (
    <div className="flex flex-col p-6 bg-white rounded-lg border border-gray-200 w-full h-[450px] overflow-y-auto"> {/*Tailwind CSS Styling */}
      <h3 className="text-lg font-medium text-gray-900">Customers Onboarding</h3> {/*Title*/}
      {customers.length === 0 ? (
        <p>No customers awaiting onboarding.</p>
      ) : (
        <ul role="list" className="divide-y divide-gray-100 mt-2">
          {customers.map((customer) => (
            <li key={customer.id} className="flex items-center justify-between py-2">
              <div className="flex flex-col">
                <span className="text-sm font-semibold text-gray-900">
                  {customer.name}
                </span>
                <span className="mt-1 text-xs text-gray-500">
                  {customer.email}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomerOnboarding;
