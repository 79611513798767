import React from 'react';
import WorkflowTasks from './workflowtasks'; // Ensure correct path
import { useParams } from 'react-router-dom';

export default function Tasks() {
  return (
    <div>
      <WorkflowTasks /> 
    </div>
  );
}
