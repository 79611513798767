import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../../services/supabaseClient';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error || !session) {
        setIsAuthenticated(false);
        setUser(null);
      } else {
        setIsAuthenticated(true);
        setUser(session.user); // Store user data for accessing metadata
      }
      
      setLoading(false);
    };

    const setupAuthStateChangeListener = () => {
      // The new Supabase API uses a new structure:
      const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
        setIsAuthenticated(!!session);
        setUser(session ? session.user : null);
      });

      return subscription;
    };

    checkAuthStatus();
    const subscription = setupAuthStateChangeListener();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const logout = async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
    setUser(null);
  };

  const value = { isAuthenticated, user, loading, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
