import React from 'react';
import CustomerTable from './CustomerTable'; // Ensure correct path

export default function Clients() {
  return (
    <div>
      <CustomerTable /> {/* Render the CustomerTable */}
    </div>
  );
}
