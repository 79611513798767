import React, { useState, useEffect } from 'react';
import { supabase } from '../../../services/supabaseClient';

const Document = ({ documentId }) => {
  const [documentPath, setDocumentPath] = useState('');

  useEffect(() => {
    const fetchDocumentPath = async () => {
      if (documentId) {
        const { data, error } = await supabase
          .from('documents')
          .select('document_path')
          .eq('id', documentId) // Assuming the primary key is called id
          .single();

        if (data) {
          setDocumentPath(data.document_path); // Assume document_path is the field for storage reference
        } else if (error) {
          console.error('Error fetching document path:', error);
        }
      }
    };

    fetchDocumentPath(); // Fetch the document path when documentId changes
  }, [documentId]);

  // Function to create a signed URL for secure access
  const getSignedUrl = async () => {
    const { data, error } = await supabase
      .storage
      .from("documents")
      .createSignedUrl(documentPath, 60); // You can specify how long the URL is valid (in seconds)

    if (error) {
      console.error('Error creating signed URL:', error);
      return null;
    }
    console.log(data);
    return data?.signedUrl;
  };

  return (
    <div className="mt-4">
      {/* If there's a document path, show the download link */}
      {documentPath ? (
        <button
          className="w-full py-2 bg-indigo-600 text-white rounded hover:bg-blue-500 font-sans"
          onClick={async () => {
            const signedUrl = await getSignedUrl();
            if (signedUrl) {
              window.open(signedUrl, '_blank');
            }
          }}
        >
          Download Related Document
        </button>
      ) : (
        <p className="text-gray-500">No document available for download.</p>
      )}
    </div>
  );
};

export default Document;
