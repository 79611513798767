import { supabase } from '../../../services/supabaseClient';

export const fetchOnboardingCustomers = async () => {
    try {
        const { error, count } = await supabase
            .from('customers')
            .select('*', { count: 'exact' })
            .eq('onboarded', false);

        if (error) {
            console.error('Error fetching customers:', error);
            return null;
        }

        return count || 0;
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
};
