import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './layouts/layout';
import Clients from './pages/customers/Clients';
import Tasks from './pages/tasks/tasks';
import Workflows from './pages/workflows/workflows';
import Dashboard from './pages/dashboard/dashboard';
import Login from './pages/auth/login';
import WorkflowDetails from './pages/workflows/workflowdetails';
import Settings from './pages/settings/settings';
import { UserProvider } from './pages/auth/UserContext';
import { AuthProvider } from './pages/auth/AuthContext';
import ProtectedRoute from './pages/auth/ProtectedRoute'; // Adjust the path to where your ProtectedRoute file is located
import SignUp from './pages/auth/signup';
import ClientDetails from './pages/customers/ClientDetails';
import SettingsLayout from './layouts/SettingsLayout';
import WorkflowTasks from './pages/settings/workflowadmintasks';
import Integrations from './pages/settings/integrations';
import NewAccount from './pages/auth/newaccount';

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/newaccount" element={<NewAccount />} />
            <Route path="/" element={<ProtectedRoute />}>
            <Route path="/signup" element={<SignUp />} />
            <Route element={<SettingsLayout />}>
            <Route path="settings" element={<Settings />} />
            <Route path="integrations" element={<Integrations />} />
            </Route>
              <Route element={<Layout />}>
                <Route path="clients" element={<Clients />} />
                <Route path="tasks" element={<Tasks />} />
                <Route path="workflows" element={<Workflows />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="workflows/:workflow_id" element={<WorkflowDetails />} />
                <Route path="clients/:id" element={<ClientDetails />} />
                <Route path="workflowtasks/:workflow_id" element={<WorkflowTasks />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
