import { createClient } from '@supabase/supabase-js';

console.log(window.location.href);

let supabaseUrl;
let supabaseKey;
if(window.location.href.includes("app.circala.ai")) {
    supabaseUrl = 'https://qldaolsqzsfyiudsttao.supabase.co';
    supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFsZGFvbHNxenNmeWl1ZHN0dGFvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ3OTg4OTEsImV4cCI6MjA0MDM3NDg5MX0.5laQ2doICz90LvR_27q0ASVwcAD-nRF1gTdPrmQhML4';


}else{
    supabaseUrl = 'https://rinttkfsuvwbynfzqxio.supabase.co';
    supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJpbnR0a2ZzdXZ3YnluZnpxeGlvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjI2MzI1NzMsImV4cCI6MjAzODIwODU3M30.U1NBrXHEMw1F2YhlnVWs0gUjMIZX1PhkIg89OdbdYIY';
}
    
export const supabase = createClient(supabaseUrl, supabaseKey);
