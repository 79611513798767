import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust the path to your AuthContext
import { useUser } from './UserContext'; // Adjust the path to your UserContext

const ProtectedRoute = () => {
  const { isAuthenticated, loading: loadingAuth } = useAuth(); // Use isAuthenticated from auth context
  const { user, loading: loadingUser } = useUser(); // Check user data

  // Handle the loading state
  if (loadingAuth || loadingUser) {
    return <div>Loading...</div>;
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated || !user) {
    console.log("User is not authenticated, redirecting to /login");
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the child components
  return <Outlet />;
};

export default ProtectedRoute;
