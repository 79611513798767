import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import { useUser } from './UserContext';
import { useAuth } from './AuthContext';

export default function SignUp() {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const { loading: loadingAuth } = useAuth();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const checkUser = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error getting session:', error.message);
        return;
      }

      if (session) {
        const userEmail = session.user.email;

        const { data: userRecord, error: userCheckError } = await supabase
          .from('users')
          .select('*')
          .eq('email', userEmail)
          .single();

        if (userCheckError) {
          console.error('Error checking user record:', userCheckError.message);
          return;
        }

        if (userRecord) {
          navigate('/dashboard');
        }
      } else {
        navigate('/login');
      }
    };

    checkUser();
  }, [navigate]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!name || !phone || !company) {
      setErrorMessage('All fields are required.');
      return;
    }

    const { data: { session }, error2 } = await supabase.auth.getSession();
    const userEmail = session.user.email;
    const id = session.user.id;
    
    const { data, error } = await supabase
      .from('users')
      .insert([{ id, email: userEmail, name, phone, company }]);
    
    if (error2) {
      setErrorMessage(error.message);
      console.error('User Insert Error:', error.message);
      return;
    }

    const userData = { email: userEmail, name, phone_number: phone, company };
    alert('Please re-authenticate to finish onboarding');
    setUser(userData);

    await supabase.auth.signOut();
    navigate('/login');
  };

  if (loadingAuth) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Complete Your Signup
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
        {errorMessage && <div className="mb-4 text-red-500 text-center">{errorMessage}</div>}
        <form onSubmit={handleSignUp} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Company</label>
            <input
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Complete Sign Up
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => navigate('/login')}
              className="flex w-full justify-center rounded-md border border-indigo-600 text-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go Back to Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
